<template>
  <div class="skills-container">
    <h2 class="section-title">Technical Skills</h2>
    <div v-for="(skills, category) in skillCategories" :key="category" class="skill-category">
      <h3 class="category-title">{{ category }}</h3>
      <div class="skill-list">
        <div v-for="skill in skills" :key="skill.name" class="skill">
          <div class="skill-name">{{ skill.name }}</div>
          <div class="skill-bar">
            <div class="skill-level" :style="{ width: skill.level + '%' }"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      skillCategories: {
        "Operating Systems": [
          { name: "Windows", level: 80 },
          { name: "macOS", level: 40 },
          { name: "Linux", level: 50 }
        ],
        "Databases": [
          { name: "SQL", level: 85 },
          { name: "MongoDB", level: 80 },
          { name: "Cassandra", level: 75 }
        ],
        "Programming": [
          { name: "Java", level: 40 },
          { name: "PHP", level: 85 },
          { name: "C#", level: 55 },
          { name: "Python", level: 50}
        ],
        "Frameworks": [
          { name: "Laravel", level: 85 },
          { name: "Spring Boot", level: 60 },
          { name: "Vue Js", level: 75 },
          { name: "React", level: 40 }
        ],
        "Help Desk Tools": [
          { name: "ServiceNow", level: 85 },
          { name: "Zendesk", level: 80 },
          { name: "Anydesk", level: 75 },
          { name: "Team viewer", level: 80 }
        ],
        "Hardware and Networking": [
          { name: "Computer repairs", level: 90 },
          { name: "Troubleshooting and maintenance", level: 85 },
          { name: "Computer networking", level: 80 },
          { name: "TCP/IP", level: 90 },
          { name: "DNS", level: 85 },
          { name: "DHCP", level: 80 }
        ],
       
        "Data Analysis": [
          { name: "MS Excel", level: 80 },
          { name: "Power BI", level: 50 },
          { name: "ETL", level: 40 }
        ],
        "DevOps and Cloud": [
          { name: "Git", level: 85 },
          { name: "AWS", level: 60 },
          { name: "Azure", level: 70 }
        ],
      
        "Agile": [
          { name: "Jira", level: 85 },
          { name: "Kanban", level: 80 },
          { name: "Scrum", level: 75 }
        ],
        // Add more categories and skills as needed
      }
    };
  }
};
</script>

<style scoped>
.skills-container {
  max-width: 900px;
  margin: auto;
  padding: 20px;
}

.section-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.skill-category {
  margin-bottom: 30px;
}

.category-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.skill-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.skill {
  width: calc(33.33% - 20px);
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.skill-name {
  font-size: 16px;
  margin-bottom: 5px;
}

.skill-bar {
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
}

.skill-level {
  height: 100%;
  background-color: #007bff;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .skill {
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 576px) {
  .skill {
    width: 100%;
  }
}
</style>
