<template>
  <div class="landing-page">

    <!-- Career Objectives Section -->
    <section class="objectives-section about-section ">
      <div class="container">
        <h2 class="section-title">Career Objectives</h2>
        <p class="objectives-description">
          I am pursuing a tech role where I can utilize my skills in IT support, data analytics, and software engineering to contribute to digital transformation initiatives across various sectors, driving innovation and making a meaningful impact in improving operational efficiency and customer experiences.
        </p>
      </div>
    </section>

    <!-- Tech Stacks Section -->
    <section class="tech-stacks-section">
      <div class="container">
        <h2 class="section-title">Tech Stacks</h2>
        <div class="tech-stacks">
          <div class="tech-stack">
            <h3 class="tech-stack-title">Programming Languages</h3>
            <p class="tech-stack-list">Java, Python, JavaScript</p>
          </div>
          <div class="tech-stack">
            <h3 class="tech-stack-title">Frameworks & Libraries</h3>
            <p class="tech-stack-list">Vue.js, React, Node.js</p>
          </div>
          <!-- Add more tech stacks as needed -->
        </div>
      </div>
    </section>

    <!-- Job Interests Section -->
    <section class="job-interests-section">
      <div class="container">
        <h2 class="section-title">Job Interests</h2>
        <p class="job-interests-description">
          Interested in opportunities that allow me to work on projects related to healthcare technology, digital transformation, and software development in dynamic and collaborative environments.
        </p>
      </div>
    </section>

    <!-- Contact Section -->
    <section class="contact-section">
      <div class="container">
        <h2 class="section-title">Contact Me</h2>
        <p>Email: ebukajohn11@gmail.com</p>
        <p>Phone: +44 7375670137</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "PortfolioLandingPage",
};
</script>

<style scoped>
/* Global Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.section-title {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Header Styles */
.header {
  background-color: #007bff;
  color: #fff;
  text-align: center;
  padding: 50px 0;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-title {
  font-size: 36px;
  margin-bottom: 20px;
}

.header-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

/* About Section Styles */
.about-section {
  background-color: #f0f0f0;
  padding: 50px 0;
}

.about-description {
  font-size: 18px;
  line-height: 1.6;
}

/* Career Objectives Section Styles */
.objectives-section {
  padding: 50px 0;
}

.objectives-description {
  font-size: 18px;
  line-height: 1.6;
}

/* Tech Stacks Section Styles */
.tech-stacks-section {
  padding: 50px 0;
}

.tech-stacks {
  display: flex;
  flex-wrap: wrap;
}

.tech-stack {
  flex: 1 1 300px;
  margin-right: 20px;
}

.tech-stack-title {
  font-size: 20px;
  margin-bottom: 5px;
}

.tech-stack-list {
  font-size: 16px;
}

/* Job Interests Section Styles */
.job-interests-section {
  background-color: #f0f0f0;
  padding: 50px 0;
}

.job-interests-description {
  font-size: 18px;
  line-height: 1.6;
}

/* Contact Section Styles */
.contact-section {
  background-color: #007bff;
  color: #fff;
  padding: 50px 0;
}

.contact-section p {
  font-size: 18px;
  margin: 10px 0;
}
</style>
