<template>
  <div class="projects-container">
    <h2 class="section-title">Projects</h2>
    <div class="projects-list">
      <div v-for="project in projects" :key="project.id" class="project">
        <div class="project-thumbnail">
          <img :src="project.thumbnail" alt="Project Thumbnail" />
        </div>
        <div class="project-details">
          <h3 class="project-title">{{ project.title }}</h3>
          <p class="project-description">{{ project.description }}</p>
          <a :href="project.link" class="project-link">View Project</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import himsdashboard from '@/assets/images/hims.png';
import smsappdashboard from '@/assets/images/smsapp.png';

export default {
  data() {
    return {
      projects: [
        {
          id: 1,
          title: "Hospital Information management ERP",
          description: "A web based Electronics Medical Records",
          thumbnail: himsdashboard ,
          link: "https://smartmedics.ebukajohn.com/login"
        },
        {
          id: 2,
          title: "Automated Clinic Appointment Reminder",
          description: "An automated clinic appointment app with Covid19 weekly vacination update",
          thumbnail: smsappdashboard ,
          link: "https://smsapp.ebukajohn.com/"
        },
        // Add more projects as needed
      ]
    };
  }
};
</script>

<style scoped>
.projects-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.section-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.projects-list {
  display: flex;
  flex-wrap: wrap;
}

.project {
  width: calc(50% - 20px);
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
}

.project-thumbnail {
  margin-bottom: 10px;
}

.project-thumbnail img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.project-details {
  padding: 10px;
}

.project-title {
  font-size: 20px;
  margin-bottom: 5px;
}

.project-description {
  margin-bottom: 10px;
}

.project-link {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
}

.project-link:hover {
  background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .project {
    width: 100%;
  }
}
</style>
