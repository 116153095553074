<template>
  <div class="certification-container">
    <h2 class="section-title">Certifications</h2>
    <div class="certification-list">
      <div v-for="(certification, index) in certifications" :key="index" class="certification-item">
        <h3 class="certification-title">{{ certification.title }}</h3>
        <p class="certification-year">{{ certification.year }}</p>
        <p class="certification-description">{{ certification.description }}</p>
        <a :href="certification.link" class="certification-link" target="_blank">View Certificate</a>
      </div>
    </div>

    <h2 class="section-title">Education</h2>
    <div class="education-list">
      <div v-for="(education, index) in educations" :key="index" class="education-item">
        <h3 class="education-title">{{ education.title }}</h3>
        <p class="education-year">{{ education.year }}</p>
        <p class="education-description">{{ education.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      certifications: [
        {
          title: "Google IT Automation with Python",
          year: "2024",
          description: "Learned automation with Python through a Google specialization course on Coursera.",
          link: "https://www.coursera.org/account/accomplishments/specialization/certificate/ABHY7DTMM97Q"
        },
        {
          title: "Google Cybersecurity",
          year: "2024",
          description: "Completed a Cybersecurity specialization course by Google on Coursera.",
          link: "https://www.coursera.org/account/accomplishments/specialization/certificate/X4YPULG63K8A"
        },
        {
          title: "The Critical Role of IT Support Staff in Healthcare",
          year: "2023",
          description: "Completed a course on the critical role of IT support staff in healthcare on Coursera.",
          link: "https://www.coursera.org/account/accomplishments/certificate/9QJPHSMTV8H7"
        },
        {
          title: "Google IT Support Professional Certificate",
          year: "2023",
          description: "Earned the Google IT Support Professional Certificate on Coursera.",
          link: "https://www.coursera.org/account/accomplishments/specialization/certificate/QJU3VZHF54LV"
        },
        {
          title: "Healthcare IT Support",
          year: "2023",
          description: "Completed a specialization course in Healthcare IT Support from Johns Hopkins University on Coursera.",
          link: "https://www.coursera.org/account/accomplishments/specialization/certificate/Q5X4CC7NYWQN"
        },
        {
          title: "Healthcare Data Security, Privacy, and Compliance",
          year: "2023",
          description: "Completed a course on Healthcare Data Security, Privacy, and Compliance on Coursera.",
          link: "https://www.coursera.org/account/accomplishments/certificate/XS4UAEC3RAT3"
        },
        {
          title: "Google Data Analytics Professional Certificate",
          year: "2022",
          description: "Earned the Google Data Analytics Professional Certificate on Coursera.",
          link: "https://www.coursera.org/account/accomplishments/specialization/certificate/7E829KHBHW7D"
        }
      ],
      educations: [
        {
          title: "Bachelor of Engineering (B.Eng) in Computer Engineering",
          year: "2010 – 2015",
          description: "Enugu State University of Science and Technology"
        },
        {
          title: "National Examination Council Certificate (NECO)",
          year: "2001 – 2007",
          description: "National High School, Rivers State"
        },
        {
          title: "First School Leaving Certificate",
          year: "1994 – 2000",
          description: "Numan III Primary School, Adamawa State"
        }
      ]
    };
  }
};
</script>

<style scoped>
/* Add your styles here */
.certification-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.section-title {
  text-align: center;
  font-size: 28px;
  color: #333;
  margin-top: 40px;
}

.certification-list,
.education-list {
  margin-top: 20px;
}

.certification-item,
.education-item {
  background-color: #f8f9fa;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.certification-title,
.education-title {
  font-size: 20px;
  margin-bottom: 10px;
  color: #007bff;
  font-weight: bold;
}

.certification-year,
.education-year {
  color: #888;
  margin-bottom: 5px;
}

.certification-description,
.education-description {
  color: #555;
  font-size: 16px;
}

.certification-link {
  color: #007bff;
  text-decoration: none;
  margin-top: 10px;
  display: inline-block;
  transition: color 0.3s;
}

.certification-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
</style>
