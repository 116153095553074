<template>
  <div class="experience-container">
    <h2 class="section-title">Work Experience</h2>
    <div class="experience-list">
      <div v-for="(experience, index) in experiences" :key="index" class="experience-item">
        <div class="experience-header">
          <h3 class="experience-title">{{ experience.title }}</h3>
          <p class="experience-period">{{ experience.period }}</p>
        </div>
        <div class="experience-details">
          <p class="experience-company">{{ experience.company }}</p>
         <p v-html="experience.description"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      experiences: [
        {
          title: "Senior Technical Health Informatics Specialist",
          company: "Catholic Caritas Foundation of Nigeria (CCFN)",
          period: "February 2022 – Present",
          description: "In this role, I have been instrumental in the development and enhancement of NigeriaMRS (EMR), utilizing Java, Spring Boot, and the Velocity template engine. This involved actively contributing to collaborative efforts aimed at improving system functionality. <br><br>Additionally, I have provided comprehensive support for electronic medical records applications, including delivering impactful user training sessions. One notable achievement includes engineering a personalized automated system for drug and viral load eligibility appointments, which significantly increased clinic appointments by 80% and improved drug adherence rates. <br><br>Furthermore, I have designed and implemented an automated reporting deck application, streamlining daily data collation and summarization processes. Alongside these responsibilities, I have maintained accurate documentation of system configurations, procedures, and troubleshooting guides, ensuring efficient management of hardware and software assets. <br><br>Moreover, I have contributed to IT infrastructure planning by assisting in upgrades and expansions, evaluating new technologies, and making recommendations for hardware and software acquisitions. Additionally, I have conducted training sessions to educate end-users on best practices, new software features, and IT security awareness, providing ongoing guidance and support to ensure effective technology utilization."
        },
        {
          title: "Health Informatics Specialist",
          company: "Catholic Caritas Foundation of Nigeria (CCFN)",
          period: "October 2021 – January 2022",
          description: "IOversaw the continuous improvement of HIS hardware and software, ensuring seamless program monitoring, quality improvement, and evaluation. Played a pivotal role in the deployment of software updates and patches, ensuring the EMR system remained current and secure. Delivered first-line technical support to end-users via phone, email, or in-person, promptly troubleshooting and resolving hardware and software issues for desktops, laptops, printers, and peripherals. Spearheaded the development and customization of software, databases, and tools to meet reporting and data output requirements for internal use and donors/GoN reporting standards.<br><br>Offered strategic Systems Integration Testing (SIT) and application support, delivering training and direct technical assistance for establishing and fortifying robust facilities at the facility, LGA, state, and national levels within the HIS framework. Guaranteeing the optimal functioning and maintenance of health information systems across designated Service Delivery Facilities and subnational Units. Spearheaded training and mentoring initiatives, ensuring the development of essential capacities among SDF level staff for effective contributions to Caritas Nigeria's broader health informatics goals. Participated actively in Agile development methodologies, including daily stand-ups, sprint planning, and retrospective meetings, ensuring the punctual and triumphant delivery of projects."
        },
        {
          title: "Program Officer Health Informatics",
          company: "Catholic Caritas Foundation of Nigeria (CCFN)",
          period: "November 2020 – October 2021",
          description: "Maintained and scaled up NigeriaMRS through regular updates and improvements. Engineered custom software/scripts, enhancing internal operations and efficiency. Deployed pertinent hardware, software, and database tools across facilities for optimal functionality.<br><br>Delivered first-line technical support to end-users via phone, email, or in-person, promptly troubleshooting and resolving hardware and software issues for desktops, laptops, printers, and peripherals. Established automated data backup and disaster recovery protocols, ensuring unwavering data integrity and system reliability. Provided on-site and remote user support, delivering training and direct technical assistance.<br><br>Guided M&E and other staff in data collation, utilizing cutting-edge innovations. Oversaw and managed the activities of my supervisees to ensure cohesive teamwork. Conducted in-depth data analysis, deriving insights to inform decision-making in healthcare delivery. Established and upheld data quality and integrity protocols for accurate healthcare information.<br><br>Collaborated with cross-functional teams to seamlessly integrate health informatics solutions into existing healthcare systems and processes. Contributed to the evaluation and optimization of health informatics systems, enhancing patient care delivery, efficiency, and safety."
        },
        {
          title: "Backend Developer and IT Support Associate",
          company: "Health in a Box – National Hospital, Abuja",
          period: "2018 – 2020",
          description: "Developed a customizable Electronic Medical Record (EMR) system automating diverse hospital units, including patient registration, medical history tracking, laboratory results management, and prescription tracking. Enforced role-based access control and robust data security measures to safeguard patient information, ensuring compliance with privacy regulations. Integrated the EMR system seamlessly with external systems for efficient data exchange and streamlined workflows.<br><br>Optimized database queries and implemented caching mechanisms to enhance the EMR system's performance for faster data retrieval and an improved user experience. Provided adept technical support, swiftly resolving critical issues related to the EMR system and minimizing disruptions to healthcare operations. Managed computer hardware and software programs with meticulous maintenance and upkeep.<br><br>Delivered first-line technical support to end-users via phone, email, or in-person, promptly troubleshooting and resolving hardware and software issues for desktops, laptops, printers, and peripherals. Installed, configured, and maintained operating systems and software applications with precision. Assisted in network connectivity and adeptly troubleshooted network-related problems to ensure uninterrupted network operations.<br><br>Set up and provided ongoing support for audiovisual equipment during meetings and presentations, ensuring a smooth technical environment."
        },
        {
          title: "System Administrator",
          company: "The BusinessPost – Area 11, Abuja",
          period: "2017 – 2018",
          description: "Developed a dynamic and interactive website, leading to increased traffic, page views, and enhanced user experience. Oversaw resolution of network and computer-related issues, ensuring seamless operations. Executed daily content uploads, maintaining a consistent and engaging online presence. Performed system maintenance tasks, including updates, patches, and security fixes, for optimal performance. Led network administration efforts, configuring devices and monitoring traffic for reliability. Provided proactive technical support, troubleshooting hardware and software problems, and addressing help desk tickets. Administered server infrastructure, ensuring proper setup, configuration, and monitoring. Implemented robust security measures, including firewalls and antivirus software, for safeguarding IT infrastructure and data integrity."
        },
        {
          title: "Help Desk Support Associate",
          company: "National Hospital, Central Business District, Abuja",
          period: "2016 – 2017",
          description: "Installed and oversaw maintenance of the hospital's computers, printers, and network infrastructure. Executed upgrades and implemented updates to enhance system performance. Diagnosed and resolved computer issues promptly and efficiently. Provided expert guidance to office personnel on technical computer inquiries. Conducted comprehensive computer training sessions for new staff members. <br><br> Spearheaded the development of an Inventory Management Application for the IT Department. Offered on-site technical support and conducted troubleshooting for hardware and software glitches. Expertly installed, configured, and maintained computer systems, peripherals, and network devices. Successfully set up and resolved issues with wired and wireless network connections. Executed hardware repairs and upgrades as necessary to optimize functionality. Assisted with the seamless installation and migration of various software applications. Delivered user training sessions on cutting-edge technologies and software tools."
        },
        {
          title: "Software Development Internship",
          company: "EmmyGold E-Solutions – Agbani Road, Enugu",
          period: "2014 – 2015",
          description: "Fixed bugs and implemented enhancements to enhance user experience on the website. Contributed to upgrading EmmyGold’s website using HTML, CSS, and JavaScript. Maintained and updated webpages on WordPress. Assisted in developing a web-based school Management System. Taught students hardware repairs, web design, and basic networking. Provided technical assistance to customers, walking them through troubleshooting hardware issues. Assisted customers with anti-virus program installations and virus removals, utilizing problem-solving skills for effective troubleshooting."
        }
      ]
    };
  }
};
</script>

<style scoped>
.experience-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.section-title {
  text-align: center;
  font-size: 24px;
  color: #333;
}

.experience-list {
  margin-top: 20px;
}

.experience-item {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
}

.experience-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.experience-title {
  font-size: 18px;
  margin-bottom: 5px;
}

.experience-period {
  color: #888;
}

.experience-company {
  font-weight: bold;
  margin-bottom: 5px;
}

.experience-description {
  color: #666;
}

@media (max-width: 768px) {
  .experience-item {
    display: block;
  }
}
</style>
