// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import AboutSection from '../components/AboutSection.vue';
import SkillsSection from '../components/SkillsSection.vue';
import ExperienceSection from '../components/ExperienceSection.vue';
import EdudcationCertificationsSection from '../components/EdudcationCertificationsSection.vue';
import ProjectsSection from '../components/ProjectsSection.vue';
import HomePage from '../components/HomePage.vue';

const routes = [
  { path: '/', redirect: '/about' }, // Redirect to the about page by default
  { path: '/home', component: HomePage },
  { path: '/about', component: AboutSection },
  { path: '/skills', component: SkillsSection },
  { path: '/experience', component: ExperienceSection },
  { path: '/education-certifications', component: EdudcationCertificationsSection },
  { path: '/projects', component: ProjectsSection },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
