<template>
  <div>
    <header>
      <h1>John Ebuka Ede</h1>
      <p>Software Engineer</p>
      <!-- Toggle Button for Mobile -->
      <button class="toggle-button" @click="toggleNavbar">☰</button>
      <!-- Navigation Links -->
      <nav class="navbar" :class="{ 'active': isNavbarActive }">
        <router-link class="nav-link" to="/about" @click="closeNavbar">About</router-link>
        <router-link class="nav-link" to="/skills" @click="closeNavbar">Skills</router-link>
        <!-- <router-link class="nav-link" to="/experience" @click="closeNavbar">Experience</router-link> -->
        <router-link class="nav-link" to="/education-certifications" @click="closeNavbar">Education/Certifications</router-link>
        <router-link class="nav-link" to="/projects" @click="closeNavbar">Projects</router-link>
      </nav>
    </header>
    <main>
      <!-- Route components will be rendered here -->
      <router-view></router-view>
    </main>
    <!-- Footer Section -->
    <footer>
      <p>&copy; 2024 John Ebuka Ede. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'AppLayout',
  data() {
    return {
      isNavbarActive: false
    };
  },
  methods: {
    toggleNavbar() {
      this.isNavbarActive = !this.isNavbarActive;
    },
    closeNavbar() {
      this.isNavbarActive = false; // Close the navbar when a menu item is clicked
    }
  }
}
</script>

<style>
/* Add your global styles here */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

header {
  background-color: #007bff;
  color: white;
  text-align: center;
  padding: 20px 0;
}

header h1 {
  margin: 0;
}

header p {
  margin: 5px 0;
}

.toggle-button {
  display: none; /* Hide toggle button by default */
}

.navbar {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.nav-link {
  text-decoration: none;
  color: white;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .toggle-button {
    display: block; /* Show toggle button on small screens */
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
  }

  .navbar {
    display: none; /* Hide navigation links by default on small screens */
    flex-direction: column;
    align-items: center;
  }

  .navbar.active {
    display: flex; /* Show navigation links when navbar is active */
  }

  .nav-link {
    margin: 5px 0;
    width: 100%; /* Make nav links take full width */
    text-align: center; /* Center align the text */
  }
}

/* Footer Styles */
footer {
  background-color: #333; /* Footer background color */
  color: white;
  text-align: center;
  padding: 20px 0;
  position: fixed; /* Fixed positioning */
  bottom: 0; /* Stick to the bottom */
  left: 0;
  width: 100%; /* Full width */
}
</style>
