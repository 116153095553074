// Import Vue
/*import { createApp } from 'vue';

// Import App component
import App from './App.vue';

// Create Vue app instance
const app = createApp(App);

// Mount the app
app.mount('#app');

*/

// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

createApp(App).use(router).mount('#app');

