<template>
  <div>
    <!-- Use Layout component for the overall structure -->
    <Layout></Layout>
  </div>
</template>

<script>
import Layout from './components/AppLayout.vue';

export default {
  components: {
    Layout,
  }
}
</script>

<style>
/* Add your global styles here */
</style>
