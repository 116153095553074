<template>
  <div class="about-container">
    <div class="about-content">
      <!-- <div class="profile-picture">
        <img :src="imageUrl" alt="Your Image">
      </div>-->
      <div class="profile-details">
        <h2>About me</h2>
        <p class="bio">
          John Ebuka Ede is a dynamic and results-driven IT professional with a passion for software development and a keen interest in leveraging technology across various sectors. With extensive experience in IT support, data analytics, and software engineering, I am adept at contributing to digital transformation initiatives, improving operational efficiency, and driving innovation across industries.<br><br>

          Throughout my career, I have acquired a diverse skill set, including expertise in operating systems such as Windows, macOS, and Linux, as well as proficiency in databases like SQL, MongoDB, and Cassandra. I am experienced in various programming languages, including Java, PHP, C#, and Python, and have worked with frameworks such as Laravel, Spring Boot, Vue.js, and React. Additionally, I am well-versed in help desk tools such as ServiceNow, Zendesk, Anydesk, and TeamViewer, and have a strong background in hardware troubleshooting, maintenance, and computer networking.<br><br>

          In my most recent role as a Senior Technical Health Informatics Specialist at the Catholic Caritas Foundation of Nigeria (CCFN), I played a pivotal role in the development and enhancement of NigeriaMRS (EMR), contributing to collaborative efforts aimed at improving system functionality. I provided comprehensive help desk support, assisting users with hardware and software issues promptly and efficiently. I have walked colleagues through step-by-step processes for troubleshooting hardware problems and provided expert guidance on technical inquiries.<br><br>

          My expertise extends beyond healthcare into various sectors such as education, media, and finance. I have developed customizable electronic medical record (EMR) systems, upgraded websites, and maintained webpages on platforms like WordPress. I have also assisted in developing web-based school management systems, taught students hardware repairs, web design, and basic networking, and provided technical assistance to customers across different industries.<br><br>

          As an application support specialist, I have delivered first-line technical support, conducted troubleshooting, and resolved critical issues related to various applications, ensuring minimal disruptions to business operations. I have also contributed to the development and customization of software, databases, and tools to meet reporting and data output requirements, enhancing internal operations and efficiency, all while nurturing my passion for software engineering.<br><br>

          Committed to ongoing learning and professional development, I hold certifications in IT automation with Python, cybersecurity, IT support, healthcare IT support, healthcare data security, privacy, and compliance, data analytics, object-oriented programming in Java, and building web applications in PHP, among others.<br><br>

          With my strong technical skills, dedication to excellence, and passion for leveraging technology to drive positive change across various sectors, I am well-equipped to contribute to innovative projects and initiatives wherever my expertise is needed.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import yourImage from '@/assets/images/passoprt.jpg';

export default {
  data() {
    return {
      imageUrl: yourImage
    };
  }
}
</script>

<style scoped>
.about-container {
  max-width: 50%; /* Adjust as needed */
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-picture {
  margin-bottom: 20px;
}

.profile-picture img {
  width: 100%; /* Adjust as needed */
  max-width: 200px; /* Adjust as needed */
  height: auto;
  border-radius: 50%; /* For a circular image */
}

.profile-details {
  text-align: justify; /* Adjust as needed */
}

h2 {
  font-size: 1.5rem; /* Adjust as needed */
}

.bio {
  line-height: 1.6;
  font-size: 1rem; /* Adjust as needed */
}
</style>
